<template>
    <div>
        <HeaderCard tittle="Disponibilidade de horário" :comFiltro="false" />
        <Chart type="bar" :data="stackedData" :options="stackedOptions" class="h-25rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    props: {
        prestadorIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 25;
                        };
                    }
                }
            ]
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            return {
                labels: this.dados.map((d) => d.diaDaSemana),
                datasets: [
                    {
                        label: 'Manhã',
                        porcentagem: this.dados.map((p) => {
                            if (p.atendimentos_matutinos != 0) {
                                return p.percentualMatutino;
                            } else {
                                return null;
                            }
                        }),
                        data: this.dados.map((p) => {
                            if (p.atendimentos_matutinos != 0) {
                                return p.atendimentos_matutinos;
                            } else {
                                return null;
                            }
                        }),
                        backgroundColor: '#36A2EB',
                        skipNull: true,
                        intersect: false
                    },
                    {
                        label: 'Tarde',
                        porcentagem: this.dados.map((p) => {
                            if (p.atendimentos_vespertinos != 0) {
                                return p.percentualVespertino;
                            } else {
                                return null;
                            }
                        }),
                        data: this.dados.map((p) => {
                            if (p.atendimentos_vespertinos != 0) {
                                return p.atendimentos_vespertinos;
                            } else {
                                return null;
                            }
                        }),
                        backgroundColor: '#FF6384',
                        skipNull: true,
                        intersect: false
                    }
                ]
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                interaction: {
                    mode: 'nearest'
                },
                plugins: {
                    legend: {
                        labels: {
                            fontColor: '#495057'
                        }
                    },
                    datalabels: {
                        formatter: (_value, context) => {
                            const porcentagem = Number(context.dataset.porcentagem[context.dataIndex]).toFixed(0);
                            const quantidade = context.dataset.data[context.dataIndex];
                            if (porcentagem && quantidade) return [`${porcentagem}%`, quantidade];
                        },
                        anchor: 'end',
                        align: 'top',
                        position: 'top',
                        color: 'black',
                        labels: {
                            value: {
                                color: 'black',
                                font: {
                                    size: 12
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                            drawBorder: false
                        }
                    }
                }
            };
        }
    },
    methods: {
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/prestadores/quantidade-atendimentos-matutinos-vespertinos', {
                    params: {
                        filtros: JSON.stringify({
                            prestadorIds: this.prestadorIds?.length ? [this.prestadorIds] : null
                        })
                    }
                });
                this.dados = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
                this.loading = false;
            }
        }
    }
};
</script>